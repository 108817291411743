import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import About from './pages/About';
import Layout from './components/Layout';
import Home from './pages/Home'; // Import Home component
import './App.css';

function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/about" element={<Layout><About /></Layout>} />
        <Route path="/Policy Master" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default AppWrapper;
