import React from 'react';
import { Helmet } from 'react-helmet';
import './About.css'; // You can create a CSS file for custom styles

function About() {
  return (
    <div className="about-page">
      <Helmet>
        <title>About Us | Policy Masterr</title>
        <meta name="description" content="Learn more about Policy Masterr, your trusted source for affordable health insurance and Medicare coverage." />
        <link rel="canonical" href="https://www.policymasterr.com/about" />
      </Helmet>

      <header className="about-header">
        <h1>About Policy Masterr</h1>
      </header>

      <main className="about-main">
        <section className="about-content">
          <h2>Our Mission</h2>
          <p>
            At Policy Masterr, we are dedicated to helping individuals and families navigate the complex world of health insurance. Our goal is to make health and Medicare coverage affordable and accessible to everyone, providing personalized plans that meet your unique needs.
          </p>
          
          <h2>Who We Are</h2>
          <p>
            Founded by a team of industry professionals, Policy Masterr is committed to offering transparent, easy-to-understand health insurance options. We partner with trusted insurance providers to bring you the best choices, whether you're looking for ACA Marketplace plans, Medicare coverage, or Final Expense options.
          </p>

          <h2>Why Choose Us?</h2>
          <ul>
            <li>Personalized recommendations tailored to your needs</li>
            <li>Compare multiple plans from top insurance providers</li>
            <li>Transparent pricing and no hidden fees</li>
            <li>Dedicated customer support to guide you every step of the way</li>
          </ul>

          <h2>Our Commitment to You</h2>
          <p>
            At Policy Masterr, we believe in building long-term relationships with our clients. We are here to help you not only find the right insurance plan but also ensure that you have the support you need to make informed decisions throughout your life.
          </p>
        </section>
      </main>

    </div>
  );
}

export default About;
