// Layout.js
import React from 'react';
import { Link } from 'react-router-dom'; // Use Link from react-router-dom for routing
import { Helmet } from 'react-helmet'; // Import Helmet
import './Layout.css';


function Layout({ children }) {
  return (
    <div className="App">
      {/* Helmet for structured data */}
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Policy Masterr",
              "url": "https://www.policymasterr.com",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "(346) 253-2556",
                "contactType": "Customer Service",
                "areaServed": "US",
                "availableLanguage": "English"
              }
            }
          `}
        </script>
      </Helmet>
      {/* Header with Navbar */}
      <header className="App-header">
        <div className="header-content">
          <h2 className="logo">
            <Link to="/" className="logo-link">
              <span className="policy">Policy</span><span className="masterr">Masterr</span>
            </Link>
          </h2>
          <p className="top-bar-content">
            (346) 253-2556 | Mon - Fri, 8 AM - 8 PM ET
          </p>
        </div>
        <nav className="navbar">
          <ul className="navbar-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><span>Currently Serving Texas</span></li>
            {/* You can add more navigation items here */}
          </ul>
        </nav>
      </header>

      {/* Main Content */}
      <main className="App-main">
        {children}
      </main>

      {/* Footer */}
      <footer className="app-footer">
  <h3>Get in Touch to Discuss Your Medicare Options</h3>
  <p>Are you 65 or turning 65 soon? Set up an appointment to explore your Medicare options and find a plan that fits your needs. Currently serving Texas residents.</p>
  <div className="footer-form">
    <input 
      type="email" 
      placeholder="Enter your email address" 
      required 
    />
    <button type="submit">Continue</button>
  </div>
  {/* Alternative Contact via Phone */}
  <div className="footer-contact">
    <p>If you're a Texas resident, or have questions about ACA Marketplace enrollment, call us directly:</p>
    <p><strong>(346) 253-2556</strong> | Mon - Fri, 8 AM - 8 PM ET</p>
  </div>
  <p>© 2024 Policy Masterr | All Rights Reserved</p>
</footer>


    </div>
  );
}

export default Layout;
